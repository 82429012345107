<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                :disabled="!search_type || search_type !== 1"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <div class="col-md-3">
            <button
              class="btn btn-gradient-dark"
              @click="GetData()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-table
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(on_page)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="`${data.item.on_page === 'login' ? 'light-info' :data.item.on_page === 'dashboard' ? 'light-success' :data.item.on_page === 'withdraw' ? 'light-warning' :'light-danger'}`"
            >
              {{ data.item.on_page === 'login' ? 'ล็อคอิน' :data.item.on_page === 'dashboard' ? 'แดชบอร์ด' :data.item.on_page === 'withdraw' ? 'รายการถอน' :data.item.on_page }}
            </b-badge>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment-timezone'
import {
  BBadge,
  // BRow,
  // BCol,
  BOverlay, BIconController,
  // BInputGroupPrepend, BModal,
  BCard, BTable,
  // BAvatar,
  BFormGroup, BFormSelect, BPagination,
  // BInputGroup, BFormInput, BButton,
  BCardBody, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    // BRow,
    BBadge,
    // BCol,
    // vSelect,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    // BButton,
    BCardBody,
    BOverlay,
    BIconController,
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        { value: 2, text: 'ล็อคอิน' },
        { value: 3, text: 'แดชบอร์ด' },
        { value: 4, text: 'รายการถอน' },
      ],
      search_type: null,
      search_val: null,
      time: null,
      time2: null,
      from: null,
      name: '',
      required,
      email,
      show: true,
      depositdata: [],
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 20,
      pageOptions: [10, 15, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'on_page', label: 'หน้าใช้งาน' },
        { key: 'username', label: 'ยูสเซอร์เนม' },
        { key: 'ip', label: 'ip address' },
        { key: 'buid', label: 'Buid' },
        { key: 'user_agent', label: 'User Agent' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Seoul')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
      ],
      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      show2: false,
      tel: null,
      search: false,
      dateStart: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      MaxAmount: 0,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.GetData()
  },
  methods: {
    GetData() {
      // let index = 0
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_type: this.search_type,
        search_val: this.search_val,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/admin/loglist', { params })
        .then(response => {
          // console.log(response.data)
          this.show = false
          this.depositdata = response.data.data
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
  color: #fff !important;
}
thead, th, tr {
  color: #fff default;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
th {
    color: #fff default;
    background-color: #000 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
